import {$API} from "boot/api";
import {clearItemSales} from "src/store/inventory-reorder/mutations";

export function addItemToReorder({commit, state, rootState}, itemData) {
  commit('addItem', itemData);

  if (!itemData.is_supplier_registered) {
    let contact = rootState.data.company_contacts.find(c => c.license_number === itemData.supplier_license_number);

    commit('addSupplierInfo', {
      license_number: itemData.supplier_license_number,
      facility_name: itemData.supplier_name,
      first_name: contact?.first_name,
      last_name: contact?.last_name,
      email: contact?.email,
      phone: contact?.phone
    });
  }

  if (typeof state.supplierPaymentTerms[itemData.supplier_license_number] === 'undefined') {
    commit('setSupplierPaymentTerms', {
      license_number: itemData.supplier_license_number,
      value: []
    });
  }

  let currentLicense = rootState.current.license;

  if (itemData.item.product) {
    $API.PRODUCTS.GET(`${itemData.item.product.id}/inventory`, {
      license: currentLicense?.id,
      inventory_unit: itemData.inventory_unit
    }).then(({data}) => {
      setItemInventory(commit, state, itemData.item.id, data);
    }).catch(() => {

    });
  } else {
    $API.METRC_ITEM.GET(`${itemData.item.id}/inventory`, {
      license: currentLicense?.id,
      inventory_unit: itemData.inventory_unit
    }).then(({data}) => {
      setItemInventory(commit, state, itemData.item.id, data);
    }).catch(() => {

    });
  }

  $API.METRC_ITEM.GET(`${itemData.item.id}/sales`, {
    license: currentLicense?.id,
    days: 900 //TODO change to 90 days
  }).then(({data}) => {
    commit('setItemSales', {
      item_id: itemData.item.id,
      sales: data.sales
    });
  }).catch(() => {
    commit('setItemSales', {
      item_id: itemData.item.id,
      sales: []
    });
  });
}

function setItemInventory(commit, state, itemId, inventory) {
  let index = state.items.findIndex(i => i.id === itemId);
  commit('setItemFieldValue', {
    index,
    name: 'current_inventory_value',
    value: inventory.inventory_value
  });
  commit('setItemFieldValue', {
    index,
    name: 'current_inventory_unit',
    value: inventory.inventory_unit
  });
}

export function clearItems({commit}) {
  commit('clearItems');
}

export function clearAllData({dispatch, commit}) {
  dispatch('clearItems');
  commit('clearSuppliersInfo');
  commit('clearItemSales');
}

export function removeItemFromReorder({commit, state}, itemId) {
  let itemIndex = state.items.findIndex(i => i.id === itemId);
  if (itemIndex >= 0) {
    commit('removeItem', itemIndex);
  }
}

export function setItemNote({commit, state, rootState}, itemData) {
  let index = state.items.findIndex(i => i.id === itemData.itemId);
  commit('setItemFieldValue', {
    index,
    name: 'note',
    value: itemData.note
  });
}
